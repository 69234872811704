import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 8000,
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h3 className="yellowtxt">Here’s what people are saying about We Rock the Spectrum Kids Gyms around the world...</h3>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                            “Fantastic place to take the kids. Very clean and well run. Equipment is well maintained and the staff are absolutely lovely. Kids always ask when we can go back”
                            </p>
                            <p className="overview">Briony L, Brighton East</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “What a wonderful place to be! We attended my nephew’s birthday party here today and were so impressed with the centre! A great deal of fun was had by one and all (old and young). The staff were lovely and very helpful. The centre is extremely well appointed and lovely and clean. Would recommend to one and all. Thanks again for a wonderful afternoon!”
                            </p>
                            <p className="overview">Melissa O, Bentleigh East</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “Both my girls love WRTS. Miss 4 attends regularly especially holiday sessions and the staff are absolutely wonderful, they all love her and it makes me feel comfortable leaving her with people who have as much love and care that I do. WRTS is like a home away from home, it also eases my mind that she can play without getting hurt or running off. Thanks to the staff for all that you do for not only the kids, but the parents as well.”
                            </p>
                            <p className="overview">
                            Stephanie P, Cranbourne West
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                            “What an amazing place for my kids, you have set up a haven not only for my kids but for myself too. Left feeling so welcome and of course will be back again”
                            </p>
                            <p className="overview">
                            Mandy K, Chelsea Heights
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                            “Thank you so much. The boys had such a blast. You really have thought of everything, safe, quiet, cosy and welcoming environment meant that I took home two very happy, calm and content kiddos. Typically after going somewhere new the boys struggle but they were both unexpectedly calm after our visit today. We can’t wait to come back. Thank you!” 
                            </p>
                            <p className="overview">
                            Kathryn M, Carrum Downs
                            </p>
                        </div>
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
